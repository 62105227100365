import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';

const Signup = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
  
    const onSubmit = async (e) => {
      e.preventDefault()
      
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user);
            navigate("/")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });

    
    }
  
  

  return (
    <main >        
        <section>
            <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <div className="flex justify-center">
                            <img src="/white-title.png" alt="logo" className="w-80 m-12" />
                        </div>

                        <h2 className="text-white text-center text-base  tracking-tight text-gray-900">
                            Sign up to request access to the admin centre.
                        </h2>                        
                    </div>

                    
                    <form onSubmit={onSubmit} className="mt-8 space-y-6" >                    
                        <div className=" space-y-6 rounded-md shadow-sm">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    First name
                                </label>
                                <input
                                    label="First name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}                                    
                                    name="firstname"
                                    type="text"                                    
                                    required
                                    autoComplete='given-name'       
                                    style={styles.textInput}                         
                                    placeholder="First name"                                   
                                />
                            </div>

                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Last name
                                </label>
                                <input
                                    label="Last name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}                                    
                                    required
                                    type="text"
                                    name="lastname"
                                    autoComplete='family-name'                                                                       
                                    style={styles.textInput}
                                    placeholder="Last name"                                    
                                />
                            </div>
                            
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                Email address
                                </label>
                                <input
                                    type="email"
                                    label="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}                                    
                                    required
                                    autoComplete='email'
                                    style={styles.textInput}
                                    placeholder="Email address"                                
                                />
                            </div>

                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    label="Create password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}                                    
                                    required
                                    style={styles.inputPassword}
                                    autoComplete='new-password'
                                    placeholder="Password"                                
                                />
                            </div>
                        </div>                        

                        <div>
                            <button
                                type="submit"                                                               
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >   
                                Sign up                                                             
                            </button>
                        </div>
                                             
                    </form>
                   

                    <p className="text-sm text-white text-center">
                        Already have an account?{' '}
                        <NavLink to="/" className="underline text-tertiary">
                            Sign in
                        </NavLink>
                    </p>
                    
                </div>
            </div>
        </section>
    </main>
  )
}

export default Signup

const styles = {
    textInput:{
        color: '#fff',
        backgroundColor: '#222d34',
        borderTopLeftRadius: '0.375rem',
        borderTopRightRadius: '0.375rem',
        borderBottom: 'none',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #222d34',
        '&:focus':{
            outline: 'none',
            border: '1px solid #3F9CFF',
            boxShadow: '0 0 0 3px rgba(63, 156, 255, 0.5)',
        }
    },
    inputPassword:{
        color: '#fff',
        backgroundColor: '#222d34',
        borderBottomLeftRadius: '0.375rem',
        borderBottomRightRadius: '0.375rem',
        borderTop: 'none',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #222d34',
        '&:focus':{
            outline: 'none',
            border: '1px solid #3F9CFF',
            boxShadow: '0 0 0 3px rgba(63, 156, 255, 0.5)',
        }
    }
}