import React from 'react';

const Navbar = ({ pageTitle }) => {

    if (!pageTitle) {
        return null;
    }
    return(
        <div className='bg-whack-navy-20 flex grow max-h-8'>
            <p className='text-whack-navy text-[12px] font-[600] p-2 uppercase font-sans-serif'>{pageTitle}</p>
        </div>
    )
}

export default Navbar;


