import React, {useEffect, useState} from 'react';
import {  getAuth, onAuthStateChanged, signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const checkAuth = getAuth();
    
    useEffect(() => {
        // Directly check if there's a user logged in when the component mounts
        if (checkAuth.currentUser) {
          navigate('/home');
          return; // Return here to prevent the rest of the useEffect code from running
        }
    
        const unsubscribe = onAuthStateChanged(checkAuth, (user) => {
          if (user) {
            navigate('/home');
          }
        });
    
        return () => unsubscribe();
      }, [checkAuth, navigate]);

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            navigate("/home")
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        
    }

    return(
        <>
            <main >        
                <section>
                    <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md space-y-8">
                            <div>
                            <div className="flex justify-center">
                                <img src="/white-title.png" alt="logo" className="w-80 m-12" />
                            </div>

                                <h2 className="text-white text-center text-base  tracking-tight text-gray-900">
                                    Please login to access the admin centre.
                                </h2>                        
                            </div>
                            
                            <form className="mt-8 space-y-6" >                            
                                <div className=" space-y-6 rounded-md shadow-sm">
                                                                            
                                    
                                    <div>
                                        <label htmlFor="email-address" className="sr-only">
                                        Email address
                                        </label>
                                        <input
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            style={styles.inputEmail}
                                            autoComplete="email"                                    
                                            required                                            
                                            className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Email address"
                                            onChange={(e)=>setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="sr-only">
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"    
                                            style={styles.inputPassword}
                                            autoComplete="current-password"                                
                                            required                                            
                                            className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Password"
                                            onChange={(e)=>setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>                        

                                <div>
                                    <button                                     
                                        onClick={onLogin}
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >      
                                        Login                                                                  
                                    </button>
                                </div>
                                
                            </form>
                        
                            <p className="text-sm text-white text-center">
                                No account yet?{' '}
                                <NavLink to="/signup" style={styles.link}>
                                    Sign up
                                </NavLink>
                            </p>
                            
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Login;

const styles = {
    link:{
        color: '#3F9CFF',
        textDecoration: 'underline'

    },
    inputEmail:{
        color: '#fff',
        backgroundColor: '#222d34',
        borderTopLeftRadius: '0.375rem',
        borderTopRightRadius: '0.375rem',
        borderBottom: 'none',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #222d34',
        '&:focus':{
            outline: 'none',
            border: '1px solid #3F9CFF',
            boxShadow: '0 0 0 3px rgba(63, 156, 255, 0.5)',
        }
    },
    inputPassword:{
        color: '#fff',
        backgroundColor: '#222d34',
        borderBottomLeftRadius: '0.375rem',
        borderBottomRightRadius: '0.375rem',
        borderTop: 'none',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #222d34',
        '&:focus':{
            outline: 'none',
            border: '1px solid #3F9CFF',
            boxShadow: '0 0 0 3px rgba(63, 156, 255, 0.5)',
        }
    }
}