import React, { useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons'; // Assuming you're using Chakra UI.

const ShortcutHint = ({ children, shortcut, description, variant = 'right' }) => {
    const [showHint, setShowHint] = useState(false);

    const getIconPosition = () => {
        switch (variant) {
            case 'left':
                return 'mr-2';
            case 'top':
                return 'mb-2';
            case 'bottom':
                return 'mt-2';
            default:
                return 'ml-2';
        }
    };

    const getTooltipPosition = () => {
        switch (variant) {
            case 'left':
                return 'right-full';
            case 'top':
                return 'bottom-full';
            case 'bottom':
                return 'top-full';
            default:
                return 'left-full';
        }
    };

    return (
        <div className="relative inline-flex items-center">
            {variant === 'left' || variant === 'top' ? (
                <InfoIcon className={`${getIconPosition()} cursor-pointer`} onMouseEnter={() => setShowHint(true)} onMouseLeave={() => setShowHint(false)} color="#3e4f5a" />
            ) : null}

            {children}
            
            {variant === 'right' || variant === 'bottom' ? (
                <InfoIcon className={`${getIconPosition()} cursor-pointer`} onMouseEnter={() => setShowHint(true)} onMouseLeave={() => setShowHint(false)} color="#3e4f5a" />
            ) : null}

            {showHint && (
                <div className={`absolute ${getTooltipPosition()} mt-2 flex items-center bg-whack-navy-20 p-3 rounded-lg border border-whack-navy-60 z-10`} style={{ "--tw-shadow-color": "255, 255, 255" }}>
                    <div className="flex gap-2 border-1 border-white">
                        {shortcut.split('+').map((key, index) => (
                            <span key={index} className="text-whack-navy border border-black px-2 py-1 rounded uppercase text-xs font-semibold shadow-inner">{key.trim()}</span>
                        ))}
                    </div>
                    <div className="ml-4 text-sm text-white">{description}</div>
                </div>
            )}
        </div>
    );
};

export default ShortcutHint;
