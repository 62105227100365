const whackTheme = {
    'whack-blue': '#1C85E8',
    'whack-blue-60': '#74C0FA',
    'whack-blue-40': '#D1E8F8',
    'whack-blue-20': '#EEF4FA',
    'whack-navy': '#202C4A',
    'whack-navy-60':'#8292A2',
    'whack-navy-40':'#D9E0E6',
    'whack-navy-20':'#F7F9FC', 
    'whack-terra-cottta': '#FF967D',
    'whack-terra-cotta-60': '#FFBDA6',
    'whack-terra-cotta-40': '#FFD7CD',
    'whack-cream-20': '#F4F2EF',
    'whack-aqua': '#36F1CD',
}
export default whackTheme;