import React, { useState, useEffect } from 'react';
import { Box, Image, Button, Modal, ModalOverlay, ModalContent, ModalBody, AspectRatio } from "@chakra-ui/react";

function ImageCarousel({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        prevImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImageIndex]);

  return (
    <Box>
      <Button onClick={prevImage}>{'<'}</Button>
      <AspectRatio ratio={1}>
        <Image 
          src={images[currentImageIndex]} 
          alt="" 
          width={'600px'}
          onDoubleClick={openModal} 
        />
      </AspectRatio>
      <Button onClick={nextImage}>{'>'}</Button>

      <Box display="flex" overflowX="scroll">
        {images.map((image, index) => (
          <AspectRatio ratio={1} key={index} opacity={index === currentImageIndex ? '1' : '0.5'}>
            <Image src={image} alt="" />
          </AspectRatio>
        ))}
      </Box>

      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalOverlay />
        <Button onClick={prevImage}>{'<'}</Button>
        <ModalContent>
          <ModalBody>
            <Image src={images[currentImageIndex]} alt="" />
          </ModalBody>
        </ModalContent>
        <Button onClick={nextImage}>{'>'}</Button>
      </Modal>
    </Box>
  );
}

export default ImageCarousel;