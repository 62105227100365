import React from 'react';
import Home from './page/Home';
import Signup from './page/Signup';
import Login from './page/Login';
import { Routes, Route } from 'react-router-dom';
import Layout from './containers/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react"
import { extendTheme } from "@chakra-ui/react";
import MythToJoin from './page/MythToJoin';
import Join from './page/Join';
import Search from './page/Search';
import Match from './page/Match';
import LoginSMS from './page/LoginSMS';



function App() {

    const config = {
      initialColorMode: "light",
      useSystemColorMode: false,
    }
    const theme = extendTheme({ config })
    
  
  
  return (
    <ChakraProvider theme={theme}>
      <Router>
              <Routes>

                <Route
                  path="/home"
                  element={
                    <Layout>
                      < Home />
                    </Layout>

                  }
                />

                <Route
                  path="/search"
                  element={
                    <Layout pageTitle={'SEARCH'}>
                      < Search />
                    </Layout>

                  }
                />

                <Route
                  path="/match"
                  element={
                    <Layout pageTitle={'match'}>
                      < Match />
                    </Layout>

                  }
                />

                <Route
                  path="/join"
                  element={
                    <Layout pageTitle={'join'}>
                      < Join />
                    </Layout>

                  }
                />

                <Route path="/" element={<LoginSMS />} />
                <Route path="/email" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/myth/:myth/join/:join" element={<Layout pageTitle={'MyHome & Daft Merger'}><MythToJoin/></Layout>} />
                <Route path="/match/:match_id" element={<Layout pageTitle={'Match - Review'}><Match/></Layout>} />
                <Route path="/join/:join" element={<Layout pageTitle={'Join'}><Join/></Layout>} />
              </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;