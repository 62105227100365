import React, { useEffect, useRef, useState } from 'react';
import withAuthentication from '../containers/withAuthentication';
import { Box, Button, ButtonGroup, Checkbox, Flex, Icon, IconButton, Link, Spinner, StatGroup, Text, useToast } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons'
import { db, getDoc } from '../firebase';
import { doc, collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ImageCarousel from '../components/ImageCarousel';
import WhackStat from '../components/WhackStat';
import { acceptMatch, rejectMatch, deleteMatch, invalidateMatch } from '../services/WhackServer';
import ShortcutHint from '../components/ShortcutHint';
import { getNextDocumentID } from '../services/FirestoreIO';
import { timeSince } from '../services/Utilities';
import whackTheme from '../whack_theme';
const BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;

const Match = ({ navigation }) => {
    const { match_id } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const [matchData, setMatchData] = useState({});
    const [lastUpdatedHumanFriendly, setLastUpdatedHumanFriendly] = useState('');

    const [srcData, setSrcData] = useState(null);
    const [srcLoading, setSrcLoading] = useState(true);

    // Create a ref to store the latest match_id
    const matchIdRef = useRef(match_id);

    const [trgtData, setTrgtData] = useState(null);
    const [trgtLoading, setTrgtLoading] = useState(true);

    const [autoForward, setAutoForward] = useState(false);
    const [autoForwardReady, setAutoForwardReady] = useState(false);

    const urlParams = new URLSearchParams(location.search);
    const toast = useToast();
    // Update the ref whenever matchIdFromParams changes
    useEffect(() => {
        matchIdRef.current = match_id;
    }, [match_id]);

    const getNextDocID = async () => {
        let documentID = await getNextDocumentID(db, urlParams, matchData, 'forward');
        console.log('getNextDocID', documentID);
        if (documentID !== null) {
            return documentID;
        }
        toast({
            title: "No more matches",
            description: "You've reached the end of the matches.",
            status: "info",
            duration: 9000,
            isClosable: true,
        })
    };

    const getLastUpdated = async () => {
        const collectionRef = collection(db, "PROD-POTENTIAL-MATCHES");
        const q = query(collectionRef, orderBy('last_updated'), limit(1));
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
            return snapshot.docs[0];
        }
    }

    const actionMatch = async (action) => {
        setAutoForwardReady(true);
        const current_match_id = matchIdRef.current;  // Get the latest match_id from the ref
        console.log('Actioning match', current_match_id, action);
        if (action === 'accept') {
            acceptMatch(current_match_id, setMatchData);
        } else if (action === 'reject') {
            rejectMatch(current_match_id, setMatchData);
        } else if (action === 'delete') {
            deleteMatch(current_match_id, setMatchData)
        } else if (action === 'invalidate') {
            invalidateMatch(current_match_id, setMatchData)
        }
        
    };

    const goToNext = async () => {
        const nextId = await getNextDocID();
        if (nextId) {
            navigate(`/match/${nextId}?${urlParams.toString()}`);
        } else {
            console.log('No next ID found');
        }
        return
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'm') {
                actionMatch('accept');
            } else if (event.key === 'r') {
                actionMatch('reject');
            }
            else if (event.key === 'd'){
                actionMatch('delete');
            } else if (event.key === 'i'){
                actionMatch('invalidate');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (match_id) {

            getMatch();
        } else {
            console.log('No match ID provided, getting last updated instead.');
            getLastUpdated().then((doc) => {
                if (doc) {
                    navigate('/match/' + doc.id);
                }
            });
        }
    }, [match_id]);

    useEffect(() => {
        if (trgtData === 'DOCUMENT_NOT_FOUND_OR_EMPTY') {
            setTrgtLoading(false);
        }
        else if (trgtData != null) {
            setTrgtLoading(false);
        }
    }, [trgtData])

    useEffect(() => {
        if (srcData === 'DOCUMENT_NOT_FOUND_OR_EMPTY') {
            setSrcLoading(false);
        }
        else if (srcData != null) {
            setSrcLoading(false);
        }

    }, [srcData])

    useEffect(() => {
        if ((autoForward && autoForwardReady) || (autoForward && matchData.status === 'INVALIDATED')){
            setAutoForwardReady(false);
            console.log('Auto-forwarding to next match in 500ms')
            setTimeout(goToNext, 100);
        }
        if (matchData.src_id) {
            getJoin(matchData.src_id, setSrcData)
            getJoin(matchData.trgt_id, setTrgtData)
        }
    }, [matchData])

    const getMatch = () => {
        const docRef = doc(db, "PROD-POTENTIAL-MATCHES", match_id);
        getDoc(docRef).then((doc) => {
            if (doc.exists && doc.data()) {
                console.log('Match data found', doc.data());
                setMatchData(doc.data());
                // Set human friendly name as x minutes / days ago
                let unixtimestamp = doc.data().last_updated;
                setLastUpdatedHumanFriendly(timeSince(unixtimestamp));
            } else {
                console.log("No such document or no data found!");
                setMatchData('DOCUMENT_NOT_FOUND_OR_EMPTY'); 
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function getJoin(join_ID, setJoinData) {
        const docRef = doc(db, "PROD-JOIN", join_ID);
        getDoc(docRef).then((doc) => {
            if (doc.exists && doc.data()) {
                console.log('Join data found', doc.data());
                setJoinData(doc.data());
            } else {
                console.log("No such document or no data found!");
                setJoinData('DOCUMENT_NOT_FOUND_OR_EMPTY');


            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function numberWithCommas(x) {
        if (!x) {
            return ''
        }
        return '€' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function toLowerCase(x) {
        return x.toString().toLowerCase();
    }
    function splitAddress(address) {
        // Split address up to the first comma
        const parts = address.split(',');
        if (parts.length > 0) {
            return parts[0].trim().split(/\s+/);  // Further split the portion before the first comma by spaces
        }
        return [];
    }
    function getLongestLength(srcAddressParts, trgtAddressParts) {
        return Math.max(srcAddressParts.length, trgtAddressParts.length);
    }
    function formatEircode(x) {
        if (!x) {
            return ''
        }
        return x.toString().replace(/\s/g, '').toUpperCase();
    }

    function formatDate(date) {
        if (!date) {
            return '';
        }

        let dateEntered;

        // Check if the date object has a toDate method
        if (typeof date.toDate === 'function') {
            dateEntered = date.toDate();
        } else if (date instanceof Date) {
            dateEntered = date;
        } else {
            console.error('Invalid date format passed to formatDate:', date);
            return '';
        }

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return dateEntered.toLocaleDateString(undefined, options);
    }

    return (
        <div className='text-whack-navy'>
            <Box id='pane' className="flex rounded-md flex-row ">
                {(srcLoading || trgtLoading) && <Spinner size='xl' />}
                {(srcData && trgtData && matchData) &&
                    <Box className='flex flex-col m-4'>
                        {srcData === 'DOCUMENT_NOT_FOUND_OR_EMPTY' && !matchData.merged ? (
                            <Text className='text-l my-2 border-2 text-center' style={{ color: '#71797E', backgroundColor:whackTheme.darkWash }}>
                                {'Source Document Not Found'.toUpperCase()}
                            </Text>
                        ) : null}
                        {matchData.merged === true ? 
                        <Link href={BASE_URL+'join/'+matchData.dest.split('/')[1]} isExternal>
                            <Text className='text-l my-2 border-2 text-center' style={{ color: '#71797E', backgroundColor:whackTheme.darkWash }}>
                                {'ALREADY MERGED ✅'.toUpperCase()}
                                
                                </Text> 
                                </Link>
                                    
                        : null}
                        <Flex className='flex-row'>
                            <Box className="flex overflow-hidden w-3/5">
                                {[...Array(getLongestLength(splitAddress(matchData.src_address), splitAddress(matchData.trgt_address)))].map((_, idx) => (
                                    <div className="mx-2" style={idx === 0 ? { marginLeft: 0 } : {}} key={idx}>
                                        <Text className='text-5xl my-2'>{toLowerCase(splitAddress(matchData.src_address)[idx] || '')}</Text>
                                        <Text className='text-5xl my-2'>{toLowerCase(splitAddress(matchData.trgt_address)[idx] || '')}</Text>
                                    </div>
                                ))}
                            </Box>
                            <Box className='flex flex-col m-4 w-2/5'>
                                <Text className='text-xl my-2'>{matchData.src_address}</Text>
                                <Text className='text-xl my-2'>{matchData.trgt_address}</Text>
                            </Box>
                        </Flex>
                        <Box className="flex w-full items-start flex-row justify-between">
                            <Box className='flex flex-col w-2/3'>
                                <Text fontSize='xs'>Matched generated {lastUpdatedHumanFriendly}</Text>
                            </Box>
                        </Box>
                        <Box className="flex w-full items-start flex-row justify-between my-4 max-h-[20vh]">
                            <Box className='flex w-2/3 flex-row'>
                                <Box className='flex flex-col w-1/3'>
                                    <Text className='border-b border-gray-300' fontWeight={'bold'}>Price</Text>
                                    {srcData ? <Text>{srcData.status} - {numberWithCommas(srcData.price_sale)}</Text> : <Text>Not Found</Text>}
                                    {trgtData ? <Text>{trgtData.status} - {numberWithCommas(trgtData.price_asking)}</Text> : <Text>Not Found</Text>}
                                    <Text fontWeight={'thin'}>{matchData.price_perc_change}%</Text>
                                    <Text fontWeight={'thin'}>{numberWithCommas(matchData.price_range_abs)}</Text>
                                </Box>
                                <Box className='flex flex-col w-1/3'>
                                    <Text className='border-b border-gray-300' fontWeight={'bold'}>Eircode</Text>
                                    <Text>{formatEircode(srcData.eircode)}</Text>
                                    <Text>{formatEircode(trgtData.eircode)}</Text>
                                </Box>
                                <Box className='flex flex-col w-1/3'>
                                    <Text className='border-b border-gray-300' fontWeight={'bold'}>Date Entered</Text>
                                    <Text>{formatDate(matchData.src_date)}</Text>
                                    <Text>{formatDate(matchData.trgt_date)}</Text>
                                    <Text fontWeight={'thin'}>{matchData.day_range_abs} days</Text>

                                </Box>
                            </Box>
                            <Box className='flex w-1/3 flex-col'>
                                <ImageCarousel images={trgtData.images} width={'40vw'} />
                            </Box>

                        </Box>
                        <Box className="flex w-full items-start flex-row justify-between my-4">
                            <Box className='flex w-1/3 flex-row'>
                                <Box className='flex flex-col w-1/3'>
                                    <Text className='border-b border-gray-300' fontWeight={'bold'}>Num Match</Text>
                                    {matchData.address_num_match
                                        ? <Box className='flex-fit bg-[#36F1CD] border-1 border-green rounded-lg' >
                                            <Text color={'#202C4A'} fontWeight={'bold'} fontSize={'xl'} textAlign={'center'}>{matchData.address_num_match.toString().toUpperCase()}</Text>
                                        </Box>
                                        : <Box className='flex-fit bg-[#FF967D] border-1 border-green rounded-lg' >
                                            <Text color={'#202C4A'} fontWeight={'bold'} fontSize={'xl'} textAlign={'center'}>{matchData.address_num_match.toString().toUpperCase()}</Text>
                                        </Box>
                                    }
                                </Box>
                                <Box className='flex flex-col w-1/3'>
                                    <Link className='flex w-60 m-auto mx-8 ' href={'https://console.cloud.google.com/firestore/databases/-default-/data/panel/PROD-POTENTIAL-MATCHES/' + matchData.id + '?&project=property-search-307613'} isExternal>
                                        <Text fontWeight={'light'} decoration={'underline'}>Firestore Doc</Text></Link>
                                </Box>
                                <Box className='flex flex-col w-1/3'>

                                </Box>
                            </Box>
                            <Box className='flex w-2/3 flex-col'>
                            </Box>

                        </Box>
                    </Box>


                }
            </Box>

            <Box id='bottomPanel' className='flex justify-center items-center absolute h-[25vh] bottom-5 left-1/2 transform -translate-x-1/2 z-10 w-4/5 min-w-[800px] max-h-[164px] max-w-[1200px] shadow-md border-whack-navy-40 border-[1px] rounded-lg	'>
                <div className="bg-whack-navy-20 text-whack-navy flex h-full flex-grow gap-4 flex-row w-11/12 rounded-lg justify-center">
                    <div id='left' className="flex h-full pl-8 mt-4">
                        <Button colorScheme='blue' leftIcon={<Icon as={ArrowLeftIcon} />} onClick={handleGoBack} className="border rounded-md">
                            Previous
                        </Button>
                    </div>
                    <div id='middle' className="flex flex-col flex-grow h-full max-w-[60vw]">
                        <StatGroup marginTop={'1rem'}>

                            <WhackStat label={'Overall Score'} score={matchData.overall_score} color={'#5863F8'} />
                            <WhackStat label={'Address'} score={matchData.address_similarity_score} unit={'%'} color={'#1d8a99'} />
                            <WhackStat label={'Distance'} score={matchData.distance_score} absolute_value={matchData.distance_abs} unit={'m'} color={'#b370b0'} />
                            <WhackStat label={'Price Change'} score={matchData.price_range_score} absolute_value={matchData.price_perc_change} unit={'%'} color={'#84DCCF'} />
                            <WhackStat label={'Days Since Ad'} score={matchData.day_range_score} absolute_value={matchData.day_range_abs} unit={' days'} color={'#5863F8'} />
                        </StatGroup>
                        {matchData.status === 'PENDING' || matchData.status === 'AUTO MATCHED' || matchData.status === 'AUTO REJECTED' ?
                            <Flex direction={'row'} gap={'2rem'} alignSelf={'center'}>
                                <Flex alignItems="center" justifyContent="center">
                                    <Text
                                        color={whackTheme.secondary}
                                        fontWeight="bold"
                                        fontSize="12"
                                        borderRadius="4px"
                                        boxShadow="sm"
                                        p={3}
                                        border="1px solid #36F1CD"
                                        _hover={{ bg: "#36F1CD", borderColor: "#087862", color: "#087862" }}
                                    >
                                        {matchData.status}
                                    </Text>
                                </Flex>
                                <ShortcutHint variant="left" shortcut="r" description="Reject the proposed match">
                                    <ButtonGroup colorScheme='blue' size='md' isAttached variant='outline'>
                                        <IconButton aria-label='Manually reject the proposed match' icon={<CloseIcon />} onClick={() => { alert('Icon Clicked!') }} />
                                        <Button onClick={() => { actionMatch('reject') }}>Reject</Button>
                                    </ButtonGroup>
                                </ShortcutHint>
                                <ShortcutHint variant='right' shortcut={'m'} description={'Accept the proposed match'}>
                                    <ButtonGroup colorScheme='blue' size='md' isAttached variant='outline' >
                                        <Button onClick={() => { actionMatch('accept') }}>Match</Button>
                                        <IconButton aria-label='Manually match the records' icon={<CheckIcon />} onClick={() => { alert('Icon Clicked!') }} />
                                    </ButtonGroup>
                                </ShortcutHint>
                                <Checkbox colorScheme='blue'  isChecked={autoForward} onChange={(e) => { setAutoForward(e.target.checked) }}>Auto-forward</Checkbox>
                            </Flex>
                            :
                            <Button colorScheme='blue' onClick={() => { alert('Button Clicked!') }} variant={'outline'}>{matchData.status}</Button>
                        }
                    </div>
                    <div id='right' className="flex h-full pr-8 mt-4">
                        <Button colorScheme='blue' leftIcon={<Icon as={ArrowRightIcon} />} onClick={goToNext} className="border rounded-md">
                            Next
                        </Button>
                    </div>
                </div>
            </Box>
        </div>
    )
}


export default withAuthentication(Match)

const styles = {
    bottomPane: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        height: '25vh',
        bottom: 0,
        left: 0,
        right: 20,
        zIndex: 1,
        width: '80%',
    }
}