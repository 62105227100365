import React, { useEffect, useState } from 'react';

import withAuthentication from '../containers/withAuthentication';
import { Button, DarkMode, Heading, Icon, IconButton, Image, Input, Spinner, StylesProvider, Text } from '@chakra-ui/react';
import { EmailIcon, SearchIcon, CheckCircleIcon } from '@chakra-ui/icons'
import { db, getDoc } from '../firebase';
import { doc } from "firebase/firestore"; // Add this import
import { useParams } from 'react-router-dom';
import ImageGallery from '../components/ImageGallery';

const MythToJoin = () => {
  const { myth, join } = useParams();
  
  const [mythID, setMythID] = useState(myth);
  const [mythData, setMythData] = useState(null);
  const [mythLoading, setMythLoading] = useState(false);

  
  const [joinID, setJoinID] = useState(join);
  const [joinData, setJoinData] = useState(null);
  const [joinLoading, setJoinLoading] = useState(false);

  const handleJoinChange = (event) => {
    setJoinID(event.target.value);
  }

  const handleMythChange = (event) => {
    setMythID(event.target.value);
  }

  useEffect(() => {
    getJoin()
    getMyth()
  }, [])

  useEffect(() => {
    setJoinLoading(false);
  }, [joinData])
  
  useEffect(() => {
    setMythLoading(false);
  }, [mythData])

  const getJoin = () => {
    const docRef = doc(db, "PROD-JOIN", joinID);
    setJoinLoading(true);
    setJoinData(null);
    getDoc(docRef).then((doc) => {
      if (doc.exists) {
        setJoinData(doc.data());
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  const getMyth = () => {
    const docRef = doc(db, "PROD-MYHOME", mythID);
    setMythLoading(true);
    setMythData(null);
    getDoc(docRef).then((doc) => {
      if (doc.exists) {
        setMythData(doc.data());
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  function numberWithCommas(x) {
    return '€' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div style={styles.main}>
      <div style={styles.pane}>
        <Text style={styles.headerText}>My Home</Text>
        <div>
          <DarkMode>
            <Input placeholder={mythID} onBlur={handleMythChange} width='80%' variant={'filled'} color={'white'} />
            <IconButton aria-label='Search database' icon={<SearchIcon />} marginLeft={6} onClick={getMyth} />
          </DarkMode>
        </div>
        {mythLoading && <DarkMode><Spinner size='xl' /></DarkMode>}
        {mythData &&
          <div style={styles.twoColLayout}>
            <div style={styles.col1}>
              <Text>ID</Text>
            </div>
            <div style={styles.col2}>
              <Text>{mythData.myhome_id}</Text>
            </div>
            <div style={styles.col1}>
              <Text>address</Text>
            </div>
            <div style={styles.col2}>
              <Text>{mythData.address}</Text>
            </div>

            <div style={styles.col1}/>
            <div style={styles.col2}>
              <div style={styles.sentence}>
                <Text style={{'marginRight':'1rem'}}>{numberWithCommas(mythData.price_asking)}</Text>
                <Text style={{'marginRight':'.5rem'}}>{mythData.beds}</Text>
                <Text style={{'marginRight':'1.5rem'}}>Bed</Text>
                <Text style={{'marginRight':'.5rem'}}>{mythData.baths}</Text>
                <Text>Bath</Text>
              </div>
            </div>

            <div style={styles.col1}>
              <Text>eircode</Text>
            </div>
            <div style={styles.col2}>
              <Text>{mythData.eircode}</Text>
            </div>
            <div>
              <ImageGallery images={mythData.images} />
            </div>

          </div>
        }
      </div>
      <div style={styles.pane}>
        <div><Text style={styles.headerText}>Daft</Text></div>
        <div>
          <DarkMode>
            <Input placeholder={joinID} onBlur={handleJoinChange} width='80%' variant={'filled'} color={'white'} />
            <IconButton aria-label='Search database' icon={<SearchIcon />} marginLeft={6} onClick={getJoin} />
          </DarkMode>
        </div>
        {joinLoading && <DarkMode><Spinner size='xl' /></DarkMode>}
        {joinData &&
          <div style={styles.twoColLayout}>
            <div style={styles.col1}>
              <Text>ID</Text>
            </div>
            <div style={styles.col2}>
              <Text>{joinData.id}</Text>
            </div>
            <div style={styles.col1}>
              <Text>address</Text>
            </div>
            <div style={styles.col2}>
              <Text>{joinData.address}</Text>
            </div>

            <div style={styles.col1}/>
            <div style={styles.col2}>
              <div style={styles.sentence}>
                <Text style={{'marginRight':'1rem'}}>{numberWithCommas(joinData.price_asking)}</Text>
                <Text style={{'marginRight':'.5rem'}}>{joinData.beds}</Text>
                <Text style={{'marginRight':'1.5rem'}}>Bed</Text>
                <Text style={{'marginRight':'.5rem'}}>{joinData.baths}</Text>
                <Text>Bath</Text>
              </div>
            </div>

            <div style={styles.col1}>
              <Text>eircode</Text>
            </div>
            <div style={styles.col2}>
              <Text>{joinData.eircode}</Text>
            </div>
            <div>
              <ImageGallery images={joinData.images} />
            </div>

          </div>
        }
      </div>
      <div style={styles.bottomPane}>
        <div style={styles.bottomPanePanel}>
          <Button leftIcon={<Icon as={CheckCircleIcon} color={'white'} />} bgColor={'#0C87F2'} textColor={'white'} variant='solid' size={'lg'} style={styles.button}>
            Join
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withAuthentication(MythToJoin)

const styles = {
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '4rem',
    padding: '1rem',
    position: 'relative',
    flexWrap: 'wrap',
  },
  headerText: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  pane: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '1rem',
    height: '100%',
    maxWidth: '45%',
    minWidth: '400px',
    backgroundColor: '#222d34',
    borderRadius: '0.5rem',

  },
  bottomPane:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',  
    bottom: 10,  
    left: 0,
    right: 0,
    zIndex: 1,
    width: '100%',
  },
  bottomPanePanel: {
    backgroundColor: '#2a3841',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    opacity: '.8',
    width: '90%',
    height: '10vh',
    borderRadius: '.75rem',
    margin: '1rem',
  },
  twoColLayout: {
    color: 'white',
    fontSize: '1rem',
    display: 'flex',
    gap: '.75rem',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    marginTop: '1rem'
  },
  col1: {
    fontWeight: 'bold',
    width: '20%',
    textAlign: 'right',

  },
  col2: {
    width: '60%',
  },
  sentence: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',

  },
  fact: {
    marginLeft: '.5rem',
    marginRight: '1.5rem',
  },
  button: {
    height: '2.5rem',
    marginTop: '1rem',
    marginBottom: '2rem',
},
}



