import React, { useEffect } from 'react';
import { Button, Flex, Image, Input, Text } from '@chakra-ui/react';
import withAuthentication from '../containers/withAuthentication';
import { search } from '../services/WhackServer'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageCarousel from '../components/ImageCarousel';
import MapView from '../components/MapView';
import whackTheme from '../whack_theme';



function StatusChip({ status }) {
  return (
    <div className="flex w-15 h-5">
      {['D', 'H', 'P'].map((letter) => (
        <div
          className={`w-5 h-5 border-whack-navy flex justify-center items-center ${status.includes(letter) ? 'border-2 font-semibold' : 'border font-light'} text-xs text-whack-navy`}
          key={letter}
        >
          {letter}
        </div>
      ))}
    </div>
  );
}
const SearchResultCard = ({ index, onSelected, isSelected, ...props }) => {
  const navigate = useNavigate();
  return (
    <Flex style={{ backgroundColor: whackTheme['whack-navy-20'], borderRadius: '16px', padding: '16px', gap: '16px', height: '150px' }}
      borderWidth={isSelected ? 2 : 0}
      borderColor={whackTheme['whack-blue-40']}
      onClick={() => { onSelected(index) }}
      className='shadow'>
      {props.images.length > 0 ?
        <Image src={props.images[0]} width={'150px'} height={'100%'} borderRadius={'4px'} />
        :
        <Image src={'/placeholder.svg'} width={'150px'} height={'100%'} borderRadius={'4px'} />
      }

      <Flex flexGrow={1} height={'100px'} direction={'column'} wrap={'wrap'} gap={'8px'}>
        <Text color={whackTheme['whack-navy']} fontSize={'xl'} fontWeight={'600'} userSelect="text">{props.address}</Text>
        <Text color={whackTheme['whack-navy']} fontSize={'l'} fontWeight={'200'}>{props.beds} Bed - {props.baths} Bath - {props.floor_area}m - {props.price_asking} asking - {props.price_sale} sale</Text>
      </Flex>
      <Flex direction={'column'} justify={'space-between'} width={'100px'} alignItems={'flex-end'}>
        <StatusChip status={props.status} />
        <Button color={whackTheme['whack-blue']} borderColor={whackTheme['whack-blue']} variant="outline" size="sm" width={'100px'} borderRadius={'4px'} onClick={() => (navigate(`/join/${props.id}`))}>View</Button>
      </Flex>
    </Flex>
  )
}

const Search = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([])
  const [currentlySelected, setCurrentlySelected] = useState(null)
  const [searchQuery, setSearchQuery] = useState(""); // Add a state for the search query
  const queryParams = new URLSearchParams(location.search);
  const searchParam = queryParams.get('query')

  useEffect(() => {
    if (searchParam) {
      setSearchQuery(searchParam);
      search(searchParam).then((res) => {
        setProperties(res['results'])
        console.log(res['results']); // Log out the properties
      })
    }
  }
    , [searchParam])

  function handleEnter(e) {
    if (e.key === 'Enter') {
      handleQueryChange(e.target.value)
    }
  }

  const handleQueryChange = (newQuery) => {
    setSearchQuery(newQuery); // Update searchQuery state on change
    queryParams.set('query', newQuery);
    if (newQuery === '') {
      queryParams.delete('query');
    }
    navigate(`/search?${queryParams.toString()}`);
  }

  const handleSelected = (selectedIdx) => {
    setCurrentlySelected(selectedIdx)
  }


  return (
    <div className='flex flex-row grow m-4'>
      <div className='flex flex-col grow'>
        <div className='flex'>
          <Input
            placeholder='Search'
            value={searchQuery} // Use the searchQuery state for the input value
            onChange={(e) => setSearchQuery(e.target.value)} // Update state on change
            size={'lg'}
            borderColor={whackTheme['whack-navy-40']}
            backgroundColor={whackTheme['whack-navy-20']}
            color={whackTheme['whack-navy']}
            dropShadow={'0 0 2px 1px rgba(130, 146, 162, 1)'}
            borderRadius={'16px'}
            onBlur={(e) => { handleQueryChange(e.target.value) }}
            onKeyDown={handleEnter}
          />
        </div>
        {properties.length > 0 ?

          <div className='flex flex-col grow max-h-[85vh] overflow-auto my-4 gap-y-2'>
            {properties.map((property, index) => {
              return <SearchResultCard {...property} index={index} onSelected={(selectedIdx) => handleSelected(selectedIdx)} isSelected={index === currentlySelected} />
            })}
          </div> : null}
      </div>
      <div className='flex flex-col justify-between mx-4 gap-4 h-full'>
        {properties.length > 0 ?
          <MapView properties={properties} handleSelected={handleSelected} currentlySelected={currentlySelected} />
          : null}
        {currentlySelected !== null ? (
          <div className="flex flex-col h-[45vh] bg-whack-navy-20 rounded-lg w-full p-4 shadow">
            <div className="flex flex-col gap-2">
              <p className="text-whack-navy text-xl font-semibold select-text">{properties[currentlySelected].address}</p>
              <p className="text-whack-navy text-lg font-light">{properties[currentlySelected].beds} Bed - {properties[currentlySelected].baths} Bath - {properties[currentlySelected].floor_area}m² - {properties[currentlySelected].price_asking} asking - {properties[currentlySelected].price_sale} sale</p>
              <ImageCarousel images={properties[currentlySelected].images} maxHeight={'240px'} width={'700px'} />
            </div>
            <div className="flex justify-end items-end w-full">
              <button className="text-whack-blue w-24 border-whack-blue border-2 hover:bg-whack-blue hover:text-white transition duration-150 ease-in-out outline-none focus:ring-4 focus:ring-whack-blue-40 rounded-md text-sm w-25 h-10" onClick={() => (navigate(`/join/${properties[currentlySelected].id}`))}>View</button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default withAuthentication(Search)
