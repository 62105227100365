import React, { useEffect, useState } from 'react';

import withAuthentication from '../containers/withAuthentication';
import { DarkMode, Flex, Input, Spinner, Text } from '@chakra-ui/react';
import { db, getDoc } from '../firebase';
import { doc } from "firebase/firestore"; // Add this import
import { Link, useParams } from 'react-router-dom';
import ImageGallery from '../components/ImageGallery';
import ImageCarousel from '../components/ImageCarousel';
import whackTheme from '../whack_theme';
const RowDetail = (props) => {
  return (
    props.value ?
    <Flex gap={'16px'}>
        <div style={styles.col1}>
          <Text>{props.label}</Text>
        </div>
        <div style={styles.col2}>
          <Text>{props.value}</Text>
        </div>
        </Flex>
      : null
  )
}

const Join = () => {
  const { join } = useParams();
  
  const [joinId, setJoinId] = useState(null);
  const [joinData, setJoinData] = useState(null);
  const [joinLoading, setJoinLoading] = useState(false);

  useEffect(() => {
    setJoinLoading(false);
  }, [joinData])

  useEffect(() => {
    console.log("Getting join from url:", join)
    if (join){
      setJoinLoading(true);
      getJoin(join)
    }
  }, [])

  useEffect(() => {
    if (joinId){
      console.log("Getting join manually entered:", joinId)
      setJoinLoading(true);
      getJoin(joinId)
    }
  }, [joinId])

  function getJoinId(e){
    setJoinId(e.target.value)
  }

  

  function getJoin(join_in){
    const docRef = doc(db, "PROD-JOIN", join_in);
    setJoinLoading(true);
    setJoinData(null);
    getDoc(docRef).then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
        setJoinData(doc.data());
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  function numberWithCommas(x) {
    if (x === null){
      return null;
    }
    return '€' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getPPSM(price, floor_area){
    if (price && floor_area){

      return numberWithCommas(Math.round(price/floor_area * 100) / 100);
    }
    return null;
  }
  return (
    <div style={styles.main}>
      <div style={styles.pane}>
        {joinLoading && <DarkMode><Spinner size='xl'/></DarkMode>}
        {!joinData && 
          <div style={styles.twoColLayout}>
            <div style={styles.col1}>
              <Text>ID</Text>
            </div>
            <div style={styles.col2}>
              <Input size={'lg'} onBlur={getJoinId} placeholder='Join ID'/>
            </div>
          </div>

        }
        {joinData &&
          <div style={styles.container}>
          <div style={styles.twoColLayout}>
            <div style={styles.col1}>
              <Text>ID</Text>
            </div>
            <div style={styles.col2}>
              <Input size={'lg'} onBlur={getJoinId} placeholder={joinData.id}/>
              <Link 
              to={`https://console.cloud.google.com/firestore/databases/-default-/data/panel/PROD-JOIN/${joinData.id}?project=property-search-307613`}
              style={{ textDecoration: 'underline', fontSize: '1rem', marginTop: '1rem'}}
              
              >
                Firestore Doc
            </Link>
            </div>

            <Flex direction={'column'} gap={'8px'}>
            <RowDetail label='Address' value={joinData.address}/>
            <RowDetail label='Beds' value={joinData.beds? joinData.beds + " Bed" : null}/>
            <RowDetail label='Baths' value={joinData.baths? joinData.baths + " Bath" : null}/>
            <RowDetail label='Asking Price' value={numberWithCommas(joinData.price_asking)}/>
            <RowDetail label='Sale Price' value={numberWithCommas(joinData.price_sale)}/>
            <RowDetail label='Price Change' value={numberWithCommas(joinData.price_change)}/>
            <RowDetail label='Type' value={joinData.type}/>
            <RowDetail label='Eircode' value={joinData.eircode}/>
            <RowDetail label='Floor Area' value={joinData.floor_area}/>
            <RowDetail label='PPSM' value={getPPSM(joinData.price_sale, joinData.floor_area)}/>
            </Flex>
            </div>
          
          <ImageCarousel images={joinData.images} maxHeight={'600px'}/>
          </div>
          
        }
      </div>
      
    </div>
  )
}

export default withAuthentication(Join)

const styles = {
  main: {
    backgroundColor: whackTheme.darkWash,
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '4rem',
    position: 'relative'
  },
  container: {
    color: whackTheme.secondary,
    display: 'flex',  
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1rem',
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  headerText: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  pane: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '2rem',
    height: '100%',
    minWidth: '400px',
    backgroundColor: whackTheme.darkWash,
  },
  bottomPane:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',  
    bottom: 0,  
    left: 0,  
    right: 0,  
    zIndex: 1,  
    width: '100%',
  },
  bottomPanePanel: {
    backgroundColor: '#2a3841',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    //opacity: '.6',
    width: '80vw',
    height: '15vh',
    borderRadius: '1rem',
    margin: '1rem',
    position: 'relative',
  },
  twoColLayout: {
    color: whackTheme.secondary,
    fontSize: '1rem',
    display: 'flex',
    gap: '.75rem',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    marginTop: '1rem',
    maxWidth: '100%',
    
    boderWidth: '1px',
    borderColor: 'white',
  },
  col1: {
    fontWeight: 'bold',
    width: '20%',
    minWidth: '100px',
    textAlign: 'right',

  },
  col2: {
    width: '60%',
  },
  sentence: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',

  },
  fact: {
    marginLeft: '.5rem',
    marginRight: '1.5rem',
  }
}



