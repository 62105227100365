// services/FirestoreIO.js

import { collection, getDocs, query, where, orderBy, startAfter, endBefore, limit, documentId } from "firebase/firestore";

let itemsPerPage = 10;

const statusFilterMap = {
    'automatched': 'AUTO MATCHED',
    'autorejected': 'AUTO REJECTED',
    'manualrejected': 'MANUALLY REJECTED',
    'manualmatched': 'MANUALLY MATCHED',
    'flagged': 'FLAGGED FOR REVIEW',
    'pending': 'PENDING',
    'invalidated': 'INVALIDATED',
}

const applyTimeFilter = (urlParams, queryConstraints) => {
    if (urlParams.has('since')) {
        let timeFilterTimestamp;
        const timeFilter = urlParams.get('since')
        const now = new Date();
        switch (timeFilter) {
            case 'today':
                timeFilterTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;
                break;
            case 'lastweek':
                timeFilterTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).getTime() / 1000;
                break;
            case 'lastfort':
                timeFilterTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14).getTime() / 1000;
                break;
            case 'lastmonth':
                timeFilterTimestamp = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getTime() / 1000;
                break;
            case 'lastyear':
                timeFilterTimestamp = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).getTime() / 1000;
                break;
            default:
                timeFilterTimestamp = null;
        }
        if (timeFilterTimestamp) {
            queryConstraints.push(where('last_updated', '>=', timeFilterTimestamp));
        }
    }
    return queryConstraints;
}
const getFilteredDocuments = async (db, urlParams, queryConstraints) => {
    const collectionRef = collection(db, 'PROD-POTENTIAL-MATCHES');
    queryConstraints = applyTimeFilter(urlParams, queryConstraints);
    
    if (urlParams.has('merged')) {
        const mergedFilter = urlParams.get('merged') === 'true';
        queryConstraints.push(where('merged', '==', mergedFilter));
    }

    if (urlParams.has('status') && urlParams.get('status') !== 'all') {
        const statusFilter = urlParams.get('status')
        queryConstraints.push(where('status', '==', statusFilterMap[statusFilter]));
    }
    if (urlParams.has('num_match') && urlParams.get('num_match') !== '') {
        const numMatchFilter = urlParams.get('num_match');
        const numMatchFilterValue = numMatchFilter === 'true';
        queryConstraints.push(where('address_num_match', '==', numMatchFilterValue));
    }

    console.log('queryConstraints', queryConstraints);
    const snapshot = await getDocs(query(collectionRef, ...queryConstraints));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

}

const getNextPage = async (db, urlParams, currentPage, currentPageNo, setCurrentPageNo) => {
    console.log('updatePage', urlParams);

    let queryConstraints = []
    const page = parseInt(urlParams.get('page') || '0', 10);
    queryConstraints.push(orderBy('last_updated', 'desc'));
    queryConstraints.push(orderBy("overall_score", "desc"));
    queryConstraints.push(orderBy(documentId()));
    queryConstraints.push(limit(itemsPerPage));
    if (page === 0 || currentPage.length === 0) {
        // First page
        setCurrentPageNo(0);
    }
    if (page > currentPageNo) {
        // Going forwards
        let cursor = currentPage[currentPage.length-1];
        queryConstraints.push(startAfter(cursor.last_updated, cursor.overall_score, cursor.id));
        setCurrentPageNo(page);
    }
    else if (page < currentPageNo && page > 0) {
        // Going backwards
        let cursor = currentPage[0];
        queryConstraints.push(endBefore(cursor.last_updated, cursor.overall_score, cursor.id));
        setCurrentPageNo(page);
    }
    return getFilteredDocuments(db, urlParams, queryConstraints);
};

const getNextDocumentID = async (db, urlParams, document, direction) => {
    let queryConstraints = []
    queryConstraints.push(orderBy('last_updated', 'desc'));
    queryConstraints.push(orderBy("overall_score", "desc"));
    queryConstraints.push(orderBy(documentId()));
    queryConstraints.push(limit(1));
    if (direction === 'forward') {
        queryConstraints.push(startAfter(document.last_updated, document.overall_score, document.id));
    }
    else if (direction === 'backward') {
        queryConstraints.push(endBefore(document.last_updated, document.overall_score, document.id));
    }
    let documents = await getFilteredDocuments(db, urlParams, queryConstraints);

    if (documents.length === 0) {
        return null;
    }
    console.log('getNextDocumentID', documents[0].id);
    return documents[0].id;
}


export { getNextPage, getNextDocumentID};