// SMS Login via Firebase.
import React, {useEffect, useState} from 'react';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { RecaptchaVerifier, onAuthStateChanged, signInWithPhoneNumber } from "firebase/auth";

const IrishPhoneNumberInput = ({ onPhoneNumberChange }) => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('+353');
    const [error, setError] = useState(null);
    
    useEffect(() => {
        // Directly check if there's a user logged in when the component mounts
        if (auth.currentUser) {
          navigate('/home');
          return; // Return here to prevent the rest of the useEffect code from running
        }
    
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            navigate('/home');
          }
        });
    
        return () => unsubscribe();
      }, [auth, navigate]);

    useEffect(() => {
        if (onPhoneNumberChange) {
            onPhoneNumberChange(phoneNumber);
        }
    }, [phoneNumber])
    
    const handleChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
    };

    const handleBlur = () => {
        // Ensure the input starts with +353
        if (!phoneNumber.startsWith('+353')) {
            setError('Please enter an Irish phone number starting with +353');
            return;
        }

        // Validate phone number format
        const regex = /^\+353(\d{9})?$/;
        if (!regex.test(phoneNumber) && phoneNumber !== '+353') {
            setError('Invalid phone number format');
        } else {
            setError(null);
        }
    };

    return (
        <div>
            <text style={{ color: '#202C4A', fontSize: '12px', fontWeight: '400', fontFamily: 'Inter, sans-serif', marginBottom: '1rem' }}>Phone Number</text>
            <label htmlFor="phone-number" className="sr-only">
                Phone Number
            </label>
            <input
                id="phone-number"
                name="phone-number"
                type="tel"
                value={phoneNumber}
                autoComplete="tel"
                required
                style={styles.inputEmail}
                placeholder="+353"
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {error && <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>}
        </div>
    );
};

const LoginSMS = () => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOTP] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSubmitPhoneNumber();
            }
        }, auth);
    }, []);

    const onSubmitPhoneNumber = (e) => {
        console.log(phoneNumber);
        e.preventDefault();
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                console.log(confirmationResult);
                setConfirmationResult(confirmationResult);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    };


    const onSubmitOTP = (e) => {
        e.preventDefault();
        if (otp.length !== 6) {
            return;
        }
        if (!confirmationResult) {
            return;
        }
        confirmationResult.confirm(otp)
            .then((userCredential) => {
                const user = userCredential.user;
                navigate("/home");
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    };

    return (
        <>
            <main className="h-screen flex">
            <section className="flex-1 bg-cover bg-center relative" 
                         style={{ backgroundImage: 'linear-gradient(rgba(32, 44, 74, .6), rgba(32, 44, 74, 0.8)), url(https://heritageireland.ie/assets/uploads/2020/03/Rock-of-Cashel-Large-640x640.jpg)' }}>
                    <div className="absolute inset-0 flex items-center justify-center">
                        {/* Add additional content here if needed, it will be centered */}
                    </div>
                </section>

                <section className="flex-1 flex items-center justify-center px-4 sm:px-6 lg:px-8" 
                         style={{ backgroundColor: '#F7F9FC' }}>
                        <div className="w-full max-w-md space-y-8">
                            <div>
                                <div className="flex justify-center">
                                    <img src="/logo.png" alt="logo" className="w-80 m-12" />
                                </div>
    
                                <h2 className="text-[#202C4A] text-center text-base tracking-tight text-gray-900">
                                    Please login to access the admin centre.
                                </h2>
                            </div>
                            <form className="mt-8 space-y-6" onSubmit={onSubmitPhoneNumber}>
                                {<IrishPhoneNumberInput onPhoneNumberChange={setPhoneNumber}/>}
                                <div className="space-y-6 rounded-md shadow-sm">
                                    {confirmationResult && (
                                        <div className="space-y-6 rounded-md shadow-sm">
                                            <label htmlFor="otp" className="sr-only">
                                                OTP
                                            </label>
                                            <input
                                                id="otp"
                                                name="otp"
                                                type="text"
                                                autoComplete="off"
                                                required
                                                style={styles.inputEmail}
                                                placeholder="OTP"
                                                onChange={(e) => setOTP(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div id="sign-in-button"></div>
                                {!confirmationResult && (<button type="submit" style={styles.button} onClick={onSubmitPhoneNumber}>Send OTP</button>)}
                                {confirmationResult && (<button type="submit" style={styles.button} onClick={onSubmitOTP}>Submit OTP</button>)}
                            </form>
    
                            <p className="text-sm text-[#202C4A] text-center">
                                No account yet?{' '}
                                <NavLink to="/signup" style={styles.link}>
                                    Sign up
                                </NavLink>
                            </p>
    
                        </div>
                </section>
            </main>
        </>
    );    
}

export default LoginSMS;

const styles = {
    link: {
        color: '#1C85E8',
        textDecoration: 'underline'

    },
    inputEmail: {
        color: '#202C4A',
        backgroundColor: '#D9E0E6',
        borderTopLeftRadius: '0.375rem',
        borderTopRightRadius: '0.375rem',
        borderBottom: 'none',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #D9E0E6',
        '&:focus': {
            outline: 'none',
            border: '1px solid #FF967D',
            boxShadow: '0 0 0 3px rgba(255, 150, 125, 0.5)',
        }
    },
    inputPassword: {
        color: '#fff',
        backgroundColor: '#D9E0E6',
        borderBottomLeftRadius: '0.375rem',
        borderBottomRightRadius: '0.375rem',
        borderTop: 'none',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #0f1524',
        '&:focus': {
            outline: 'none',
            border: '1px solid #FF967D',
            boxShadow: '0 0 0 3px rgba(63, 156, 255, 0.5)',
        }
    },
    button: {
        color: '#fff',
        backgroundColor: '#1C85E8',
        borderRadius: '0.375rem',
        padding: '0.75rem 1rem',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        fontFamily: 'Inter, sans-serif',
        transition: 'all 0.2s ease-in-out',
        border: '1px solid #3F9CFF',
        '&:hover': {
            backgroundColor: '#3F9CFF',
            color: '#fff',
            border: '1px solid #3F9CFF',
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: '#3F9CFF',
            color: '#fff',
            border: '1px solid #3F9CFF',
            boxShadow: '0 0 0 3px rgba(63, 156, 255, 0.5)',
        }
    }
}