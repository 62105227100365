import { Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import React from 'react';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import { Icon } from '@chakra-ui/react'
export default function WhackStat({ label, score, absolute_value, color, unit='%' }) {
    let perc_score = Math.round(score * 100)
    if (label === 'Overall Score') {
        perc_score = Math.round(score)
    }
    else if (label === 'Address'){
        perc_score = Math.round(score*100)
    }
     
    let styles = getStyles(color)
    return (
        <Stat>
            <div style={styles.element}>
                <div style={{ ...styles.topBorder, width: `${perc_score}%` }}></div>
            </div>
            <StatLabel>{label}</StatLabel>
            
            {absolute_value ? (
                <StatNumber>{absolute_value}{unit}</StatNumber>
            ) : (
                <StatNumber>
                    {label === 'Overall Score' ? (
                        <Icon as={ellipsisVerticalOutline} color={perc_score > 60 ? '#36F1CD' : '#D90368'} opacity={'100%'} marginRight={'0.25rem'} />
                    ) : null}
                        {perc_score }{unit}
                </StatNumber>
            )}
            
            {absolute_value ? (
                <StatHelpText>
                    <Icon as={ellipsisVerticalOutline} color={perc_score > 60 ? '#36F1CD' : '#D90368'} opacity={'100%'} marginRight={'0.25rem'} />
                    Score - {perc_score}%
                </StatHelpText>
            ) : null}
        </Stat>
    );
}
const getStyles = (color) => {
    return{
    element: {
        position: 'relative',  // Ensure the positioning context is set to the element
        width: '90%',  // Set to the desired width
        backgroundColor: '#445c69',  // Optional: default color is #000
        //border: '1px solid #ccc',  // Optional: a border around the element
        rightBorderWidth: '1px',  // Optional: thickness of the right border
        rightBorderColor: '#ccc',  // Optional: color of the right border
        borderRadius: '4px',
        marginBottom: '0.5rem',
      },
      topBorder: {
        height: '6px',  // Set to the desired thickness of the colored line
        backgroundColor: color,  // Set to the desired color of the colored line
        borderRadius: '4px',
      },
    }
};