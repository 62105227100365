import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import withAuthentication from '../containers/withAuthentication';
import { collection, doc, getDocs, limit, orderBy, query, startAfter, startAt, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Button, Checkbox, Link, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { bulkAcceptMatch, bulkRejectMatch } from '../services/WhackServer';
import { getNextPage } from '../services/FirestoreIO';
import whackTheme from '../whack_theme';
import { color } from 'framer-motion';
const BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;

const Home = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]); // To keep track of selected document rows
  const [bulkAction, setBulkAction] = useState(""); // To store the bulk action choice (Bulk Accept/Bulk Reject)
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState();
  const itemsPerPage = 10;
  const toast = useToast();

  const queryParams = new URLSearchParams(location.search);
  const queryParamsString = location.search;
  const mergedFilter = queryParams.get('merged') === 'true';
  const statusFilter = queryParams.get('status')
  const timeFilter = queryParams.get('since')
  const numMatchFilter = queryParams.get('num_match')
  const page = parseInt(queryParams.get('page')) || 0;

  useEffect(() => {

    if (page !== 0 && (documents.length === 0 || documents.length < itemsPerPage)) {
      setDisableNextButton(true)
      toast({
        title: "No results found.",
        description: "There are no more documents to display.",
        status: "info",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      setDisableNextButton(false);
    }
  }, [documents]);

  const fetchDocuments = async () => {
    console.log('FetchingDocuments')
    if (page > 0 && documents.length === 0) {
      // If we are on a page other than the first page, and we don't have any documents, it means user deep linked to the page.
      // Deeplinking isnt supported with pagination, so we need to fetch the first page of documents.
      console.log('Deeplinking to a page isnt supported, redirecting to first page.')
      queryParams.delete('page');
    }
    try {
      const documentsData = await getNextPage(db, queryParams, documents, currentPageNo, setCurrentPageNo);
      console.log(documentsData);
      setDocuments(documentsData);

    } catch (error) {
      console.error("Error fetching documents:", error);
      // Handle the error appropriately
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [queryParamsString]);

  const handlePageChange = (newPage) => {
    queryParams.set('page', newPage.toString());
    if (newPage === 0) {
      queryParams.delete('page');
    }
    navigate(`/home?${queryParams.toString()}`);
  };

  const handleStatusChange = (newStatus) => {
    queryParams.set('status', newStatus);
    if (newStatus === '') {
      queryParams.delete('status');
    }
    navigate(`/home?${queryParams.toString()}`);
  }

  const handleNumMatchChange = (newNumMatch) => {
    queryParams.set('num_match', newNumMatch);
    if (newNumMatch === '') {
      queryParams.delete('num_match');
    }
    navigate(`/home?${queryParams.toString()}`);
  }

  const handleTimeChange = (newTime) => {
    queryParams.set('since', newTime);
    if (newTime === '') {
      queryParams.delete('since');
    }
    navigate(`/home?${queryParams.toString()}`);
  }

  const handleMergedChange = (isChecked) => {
    if (isChecked) {
      queryParams.set('merged', 'true');
    } else {
      queryParams.delete('merged');
    }
    navigate(`/home?${queryParams.toString()}`);
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      handlePageChange(page - 1);
    }
  };

  const handleNextPage = () => {
    handlePageChange(page + 1);
  };

  const handleSelectDoc = (docId, isChecked) => {
    if (isChecked) {
      setSelectedDocs(prev => [...prev, docId]);
    } else {
      setSelectedDocs(prev => prev.filter(id => id !== docId));
    }
  };

  const handleBulkAction = async () => {
    if (!bulkAction || selectedDocs.length === 0) return;
    let response;
    if (bulkAction === 'accept') {
      response = await bulkAcceptMatch(selectedDocs);
    }
    else if (bulkAction === 'reject') {
      response = await bulkRejectMatch(selectedDocs);
    }
    console.log(response);
    if (response.status === 200) {
      toast({
        title: "Bulk Action Completed.",
        description: response.message,
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      })
    }
    setBulkAction('');
    setSelectedDocs([]);
    fetchDocuments();
  }

  return (
    <div className='flex flex-col text-whack-navy'>
      <Box className="flex justify-end gap-8 p-4 px-16">
        <Text className="text-2xl font-bold">Potential Matches</Text>
        <Box>
          <Select
            className="w-56px "

            value={statusFilter}
            placeholder="Choose status"
            onChange={(e) => {
              handleStatusChange(e.target.value)
            }}
          >
            <option value="automatched">AUTO MATCHED</option>
            <option value="autorejected">AUTO REJECTED</option>
            <option value="manualrejected">MANUALLY REJECTED</option>
            <option value="manualmatched">MANUALLY MATCHED</option>
            <option value="flagged">FLAGGED FOR REVIEW</option>
            <option value="pending">PENDING</option>
            <option value="invalidated">INVALIDATED</option>
          </Select>
        </Box>
        <Box>
          <Select
            className="w-56px"

            value={numMatchFilter}
            placeholder="# Match"
            onChange={(e) => {
              handleNumMatchChange(e.target.value)
            }}
          >
            <option value="true"> ✅ True</option>
            <option value="false"> 🛑 False</option>
          </Select>
        </Box>
        <Box>
          <Select
            className="w-56px"

            value={timeFilter}
            placeholder="Updated since"
            onChange={(e) => {
              handleTimeChange(e.target.value)
            }}
          >
            <option value="today">Today</option>
            <option value="lastweek">Last Week</option>
            <option value="lastfort">Last Fortnight</option>
            <option value="lastmonth">Last Month</option>
            <option value="lastyear">Last Year</option>
            <option value="">All Time</option>
          </Select>
        </Box>
        <Checkbox
          isChecked={mergedFilter}
          onChange={(e) => handleMergedChange(e.target.checked)}
        >
          Merged
        </Checkbox>

      </Box>
      <Box width="100%" style={styles.tableContainer}>
        <Table style={styles.table} >
          <Thead>
            <Tr>
              <Th style={{ ...styles.tableHeader, maxWidth: '30vw' }}>Address</Th>
              <Th style={styles.tableHeader}>Status</Th>
              <Th style={styles.tableHeader}>Score</Th>
              <Th style={styles.tableHeader}>Num Match</Th>
              <Th style={styles.tableHeader}>Price Change</Th>
              <Th style={styles.tableHeader}>Distance</Th>
              <Th style={styles.tableHeader}>Days</Th>
              <Th style={styles.tableHeader}>Bulk Action</Th>
              <Th style={styles.tableHeader}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {documents.map((doc) => (
              <Tr key={doc.id} style={styles.tableRow}>
                <Td style={styles.tableCell}>
                  <Box flexDirection={'column'}>
                    <Text>{doc.src_address.substring(0, 20)}</Text>
                    <Text>{doc.trgt_address.substring(0, 20)}</Text>
                  </Box>
                </Td>
                <Td style={styles.tableCell}>{doc.status}</Td>
                <Td style={styles.tableCell}>{doc.overall_score}%</Td>
                <Td style={styles.tableCell}>{doc.address_num_match ? '☑️' : '✖️'}</Td>
                <Td style={styles.tableCell}>{doc.price_perc_change}%</Td>
                <Td style={styles.tableCell}>{doc.distance_abs}m</Td>
                <Td style={styles.tableCell}>{doc.day_range_abs} days</Td>
                <Td style={styles.tableCell}>
                  {bulkAction !== '' && (
                    <Checkbox
                      isChecked={selectedDocs.includes(doc.id)}
                      onChange={(e) => handleSelectDoc(doc.id, e.target.checked)}
                    >
                      {(bulkAction === 'accept') ? "Accept" : "Reject"}
                    </Checkbox>
                  )}
                </Td>
                <Td style={styles.tableCell}>
                <Link 
                  as={RouterLink} 
                  style={styles.link} 
                  to={`${BASE_URL}match/${doc.id}?${queryParams}`} 
                  target="_blank">
                    {'View Match'}
                </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {/* Pagination Controls, show current page number and allow go forwards and backwards.*/}
        <Box className="w-full px-24 flex justify-between items-center p-4 bg-gray-200">
          <Button
            onClick={handlePreviousPage}
            isDisabled={page === 0}
            className="w-36 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Previous
          </Button>
          <Text className="text- font-light">
            Currently on Page {page}
          </Text>
          {disableNextButton ?
            <Button
              onClick={handleNextPage}
              isDisabled={true}
              className="w-36 bg-gray-400 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
            >
              No More Pages
            </Button>
            :
            <Button
              onClick={handleNextPage}
              className="w-36 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Next
            </Button>
          }
        </Box>
        <Box className="flex w-131 items-center space-x-4 p-4">
          <Select
            className="w-56px bg-whack-dark-navy border border-gray-400 hover:border-gray-500 rounded focus:outline-none focus:bg-whack-light-navy focus:border-gray-500 p-2"

            value={bulkAction}
            placeholder="Choose action"
            onChange={(e) => {
              setBulkAction(e.target.value)
              if (e.target.value === '') {
                setSelectedDocs([]);
              }
              else {
                setSelectedDocs(documents.map(doc => doc.id));
              }
            }}
          >
            <option value="accept">Bulk Accept</option>
            <option value="reject">Bulk Reject</option>
          </Select>
          <Button
            className="w-80 bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline-blue"
            onClick={handleBulkAction}
          >
            {bulkAction === 'accept' ? "Accept Selected" : "Reject Selected"}
          </Button>
          <Button
            className="w-64 bg-red-500 hover:bg-red-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline-red"
            onClick={() => setSelectedDocs([])}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default withAuthentication(Home)

const styles = {
  table: {
    maxWidth: '80%',
    margin: '0 auto',
  },
  tableContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
    position: 'relative',
    flexWrap: 'wrap',
    overflowX: 'auto',
    color: whackTheme.secondary,
  },
  tableHeader: {
    padding: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  },
  tableRow: {
    height: '44px',
  },
  tableCell: {
    padding: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    color: whackTheme.primary,
    textDecoration: "underline",
  },
  paginationButton: {
    marginTop: '1rem',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },

}



