import { auth } from "../firebase";
const SERVER_URL = process.env.REACT_APP_SERVER_BASE_URL;
async function callAuthenticatedEndpoint(url, params = null, data = null, method = 'POST') {
    let accessToken;
    try {

        accessToken = await auth.currentUser.getIdToken();
    } catch (e) {
        console.error('Error getting token: ', e);
        throw e;
    }

    const myHeaders = new Headers({
        "Authorization": "Bearer " + accessToken,
        "Content-Type": "application/json",
    });

    const requestOptions = {
        method: method,
        headers: myHeaders,
        body: data ? JSON.stringify(data) : null,
        redirect: 'follow'
    };

    // Construct query string from params
    const queryString = params 
        ? '?' + new URLSearchParams(params).toString()
        : '';
    const endpoint = SERVER_URL + url + queryString;
    console.log('API request: ', endpoint, requestOptions);
    try {
        const response = await fetch(endpoint, requestOptions);
        const result = checkStatus(response);  // ensure that this function is defined
        
        console.log('API result: ', result); 
        return result;
    } catch (error) {
        console.error('API error: ', error);
        throw error;
    }
}

async function checkStatus(response) {
    if (response.ok) {
        return response.json();
    } else {
        const body = await response.json();

        const error = new Error(`HTTP Error: ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        error.body = body;

        console.error('API error: ', error);
        throw error;
    }
}

async function bulkAcceptMatch(matchIDs) {
    try {
        const response = await callAuthenticatedEndpoint(`bulk_accept_match`, null, { match_ids: matchIDs });
        return response;
    } catch (error) {
        console.error('Error accepting match: ', error);
    }
}

async function bulkRejectMatch(matchIDs) {
    try {
        const response = await callAuthenticatedEndpoint(`bulk_reject_match`, null, { match_ids: matchIDs });
        return response;
    } catch (error) {
        console.error('Error rejecting match: ', error);
    }
}

function acceptMatch(matchId, setMatch) {
    console.log('acceptMatch', matchId);
    let url = `accept_match?match_id=${matchId}`;
    callAuthenticatedEndpoint(url).then((doc) => {
        setMatch(doc);
    }
    ).catch((error) => {
        console.error('Error accepting match: ', error);
    })
}

function rejectMatch(matchId, setMatch) {
    callAuthenticatedEndpoint(`reject_match?match_id=${matchId}`).then((doc) => {
        setMatch(doc);
    }
    ).catch((error) => {
        //print out error message/body
        console.error('Error accepting match: ', error);
    })
}
function invalidateMatch(matchId, setMatch) {
    callAuthenticatedEndpoint(`invalidate_match?match_id=${matchId}`).then((doc) => {
        setMatch(doc);
    }
    ).catch((error) => {
        //print out error message/body
        console.error('Error accepting match: ', error);
    })
}

function deleteMatch(matchId, setMatch ) {
    callAuthenticatedEndpoint("match/" +matchId,null, null, 'DELETE').then((doc) => {
        setMatch(doc);
    }
    ).catch((error) => {
        //print out error message/body
        console.error('Error deleting match: ', error);
    })
}

function search(query) {
    return callAuthenticatedEndpoint('/search/address', { 'query':query }, null, 'GET');
}
export { acceptMatch, rejectMatch, deleteMatch,invalidateMatch, bulkAcceptMatch, bulkRejectMatch, search };