import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Text } from '@chakra-ui/react';

import { onAuthStateChanged } from 'firebase/auth';
import whackTheme from '../whack_theme';

const Sidebar = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);

    const handleLogout = () => {

        signOut(auth).then(() => {
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
        });

    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUsername(user.displayName);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div style={styles.sidebar}>
            <ul>
                <NavLink
                    to="/home">
                    <div className="flex justify-center">
                        <Image src="/logo.png" width='120px' margin={'32px'} alt="logo" resize={'contain'}/>
                    </div>
                </NavLink>

                <NavLink
                    to="/home"
                    style={({ isActive }) => {
                        return isActive ? styles.activeTab : styles.inactiveTab;
                    }}
                >
                    Home
                </NavLink>
                <NavLink
                    to="/match"
                    style={({ isActive }) => {
                        return isActive ? styles.activeTab : styles.inactiveTab;
                    }}
                >
                    Match
                </NavLink>
                <NavLink
                    to="/search"
                    style={({ isActive }) => {
                        return isActive ? styles.activeTab : styles.inactiveTab;
                    }}
                >
                    Search
                </NavLink>
                <NavLink
                    to="/join"
                    style={({ isActive }) => {
                        return isActive ? styles.activeTab : styles.inactiveTab;
                    }}
                >
                    View Join
                </NavLink>

            </ul>
            <div style={styles.sidebarFooter}>
                <Text style={styles.systemMessage}>Logged in as {username}</Text>
                <Button onClick={handleLogout} variant='outline' color={'#202C4A'} textColor={'#202C4A'} style={styles.button} >
                    Logout
                </Button>
            </div>
        </div>
    )
}

export default Sidebar

const styles = {
    sidebar: {
        width: '100%',
        maxWidth: '180px',
        height: '100%',
        padding: '1rem',
        backgroundColor: whackTheme['whack-navy-20'],
        borderRightWidth: '1px',
        borderRightColor: whackTheme['whack-navy-40'],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    systemMessage: {
        color: whackTheme['whack-navy'],
        fontSize: '0.8rem',
        textAlign: 'center',
        marginTop: '1rem'
    },
    button: {
        width: '80%',
        height: '2.5rem',
        marginTop: '1rem',
        marginBottom: '2rem',
        borderWidth: '1px',
        borderColor: whackTheme['whack-navy'],
    },
    sidebarFooter: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    activeTab: {
        color: '#202C4A',
        backgroundColor: 'rgba(12, 135, 242, 0.1)',
        //borderLeft: '2px solid #0C87F2',

        fontSize: '12px',
        fontWeight: '600',
        textTransform: 'uppercase',
        textAlign: 'left',
        width: '100%',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0 1rem',
        borderRadius: '0.5rem',
        //borderTopRightRadius: '.5rem', 
        //borderBottomRightRadius: '.5rem', 
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    inactiveTab: {
        color: whackTheme['whack-navy'],
        width: '100%',
        height: '2.5rem',
        display: 'flex',
        borderRadius: '0.5rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontSize: '12px',
        fontWeight: '600',
        textTransform: 'uppercase',
        textAlign: 'left',
        width: '100%',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0 1rem',

    }
}