import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function withAuthentication(Component) {
  return function AuthenticatedComponent(props) {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (!user) {
          navigate('/'); // Redirect to the login page if the user is not authenticated
        }
        setIsLoading(false);
      });

      return () => unsubscribe();
    }, [auth, navigate]);

    return !isLoading ? <Component {...props} /> : null; // Render the protected component only if the user is authenticated
  };
}

export default withAuthentication;
