import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc, doc, collection } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAozCbTCpRZ1SAXahGl7kCGYxGurFGmghE",
    authDomain: "property-search-307613.firebaseapp.com",
    projectId: "property-search-307613",
    storageBucket: "property-search-307613",
    messagingSenderId: "400769971164",
    appId: "1:400769971164:web:3c65a97207ddab7b141519",
    measurementId: "G-R6V9RCD5Z9",
    databaseURL: "https://property-search-307613.firebaseio.com/"
  };

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
export { db, auth, doc, getDoc, collection, firebaseConfig };