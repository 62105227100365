import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Layout = ({pageTitle, children}) => {
    return (
        <div className="flex bg-whack-blue-20 h-screen w-screen">
            {/* Sidebar */}
            <div className="hidden md:block w-[180px]">
                <Sidebar />
            </div>

            {/* Main Content Area */}
            <div className="flex flex-col h-full grow">
                {/* Navbar */}

                <Navbar pageTitle={pageTitle} />
                {children}
            </div>
        </div>
    );
}

export default Layout;