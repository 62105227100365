import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ColorModeScript, extendTheme } from '@chakra-ui/react'
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}
const theme = extendTheme({ config })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    {/* <Provider store={store}> */}
      {/* <Router> */}
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
      {/* </Router>       */}
    {/* </Provider>     */}

  </React.StrictMode>
);

