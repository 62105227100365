import React, {View} from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import { Text } from '@chakra-ui/react';
const createIcon = (iconUrl, price) => L.divIcon({
    className: 'custom-icon', // CSS class for styling
    html: `
      <div style="position: relative;">
        <img src="${iconUrl}" width="30" height="30" />
        <span style="position: absolute; top:15px; left:8px; width: 30px; text-align: center; font-size: 10px; font-weight: 800; color: ${iconUrl === '/AquaPinFilled.png' ? '#202C4A' : 'white'};">${price ? Math.round(price / 1000) : ''}</span>
      </div>
    `,
    iconSize: [30, 30],
  });


export default function MapView({ properties, handleSelected, currentlySelected}) {

  return (
    <div className='flex flex-grow rounded-lg shadow'>
              {properties.length > 0 ?
                <MapContainer center={[properties[0].lat, properties[0].lng]} zoom={16} style={{ height: "100%", width: "100%", borderRadius: '16px' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {properties.map((property, index) => {
                    if (property.lat === null || property.lng === null) {
                      return null;
                    }
                    return (
                      <Marker
                        key={index}
                        position={[property.lat, property.lng]}
                        eventHandlers={{
                          click: () => {
                            handleSelected(index);
                          },
                        }}
                        icon={index === currentlySelected ? createIcon('/AquaPinFilled.png', property.price_asking) : createIcon('/NavyPinFilled.png', property.price_asking)}
                      >
                      </Marker>)
                  }
                  )}
                </MapContainer>
                :
                <Text fontSize='xl' fontWeight='600'>No properties found</Text>
              }

          </div>
  );
}
