import { Box, Flex,Grid, Image } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

function ImageCarousel({ images, maxHeight, thumbnailPosition, width }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        prevImage();
      } else if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImageIndex]);

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [images])

  const flexDirection = thumbnailPosition === 'underneath' ? 'column' : 'row';


  return (
    <Flex direction={flexDirection} width={width} maxWidth={width} overflow={'clip'} justifyContent={'center'} overflow="scroll">
      <Box position="relative">
        <Image
          src={images[currentImageIndex]}
          alt=""
          onDoubleClick={openModal}
          objectFit="contain"
          width="100%"
          maxHeight={maxHeight}
        />
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          width="10%"
          bg="transparent"
          _hover={{ bg: "whiteAlpha.500" }}
          onClick={prevImage}
          cursor="pointer"
        />
        <Box
          position="absolute"
          top="0"
          bottom="0"
          right="0"
          width="10%"
          bg="transparent"
          _hover={{ bg: "whiteAlpha.500" }}
          onClick={nextImage}
          cursor="pointer"
        />
      </Box>
      <Grid
        id='imageThumbnailContainer'
        maxWidth={`${200}px`}
        templateColumns="repeat(6, minmax(40px, 1fr))"
        gap={`2px`}
        marginLeft={'2px'}
        alignItems="flex-start"
        justifyContent="start"
        overflow={'clip'}>
        {images.map((image, index) => (
          <Box
            key={index}
            as="img"
            src={image}
            alt=""
            style={{ width: '40px', height: '40px', opacity: index === currentImageIndex ? '1' : '0.5' }}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </Grid>

      {modalOpen && (
        <Box onClick={closeModal} position="fixed" top={0} left={0} width="100vw" height="100vh" backgroundColor="rgba(0, 0, 0, 0.5)" zIndex={'1050'}>
          <Box position="absolute" top="0" bottom="0" left="200" width="10%" bg="transparent" _hover={{ bg: "whiteAlpha.500" }} onClick={prevImage} cursor="pointer" />
          <Image src={images[currentImageIndex]} alt="" width="100%" height="100%" objectFit="contain" />
          <Box position="absolute" top="0" bottom="0" right="200" width="10%" bg="transparent" _hover={{ bg: "whiteAlpha.500" }} onClick={nextImage} cursor="pointer" />
        </Box>
      )}
    </Flex>

  );
}

export default ImageCarousel;